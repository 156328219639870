import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const TermsAndConditions = () => {
  return (
    <Layout location={"privacy"} dark={true} title={"Privacy Policy"}>
      <div>
        <article
          className="blog-post w-full md:w-10/12 lg:w-7/12 mx-auto px-4 lg:px-2"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <h2
              className={`  mb-12 article-title text-center`}
              itemProp="headline"
            >
              Terms and Conditions
            </h2>
          </header>

          <div className="article-content mb-32" itemProp="articleBody">
            <p>
              By using the Myradio.click and Console.myradio.click website, you
              accept the following conditions:
            </p>

            <p className="text-lg mb-4">
              1. MyRadio.Click accepts no responsibility for the completeness or
              accuracy of any of the information contained on or accessed
              through the myradio.click site and makes no representations about
              its suitability for any particular purpose. Users should make
              their own judgments about those matters. To the extent permitted
              by law, the MYRADIO.CLICK excludes all liability for loss or
              damage arising from the use of, or reliance on, the information
              contained on or accessed through the Myradio.click site whether or
              not caused by any negligence on the part of the MYRADIO.CLICK or
              its listeners, employees, contractors or agents.
            </p>

            <p className="text-lg mb-4">
              2. Information or materials which are offensive, pornographic,
              unsuitable for minors’ access or otherwise of a criminal or
              violent nature may be accessible through the myradio.click site
              either as a result of hacking or material placed on linked
              websites. The MYRADIO.CLICK makes no representations as to the
              suitability of the information accessible for viewing by minors or
              any other person.
            </p>

            <p className="text-lg mb-4">
              3. You assume all risks associated with use of the myradio.click
              site including, without limitation:
            </p>

            <p className="text-lg mb-4 pl-4">
              3.1 The risk of your computer, software or data being damaged by
              any virus which might be transmitted or activated via the
              Myradio.click site or your access to it; or
            </p>

            <p className="text-lg mb-4 pl-4">
              3.2 The risk that the content of the myradio.click site and linked
              websites complies with the laws of any country outside Australia.
            </p>

            <p className="text-lg mb-4">
              4. Your use of the MYRADIO.CLICK site may be logged for the
              purpose of security and usage monitoring.
            </p>

            <p className="text-lg mb-4">
              5. Unauthorised use of the myradio.click site could result in
              criminal prosecution.
            </p>

            <p className="text-lg mb-4">
              6. The Myradio.click site is hosted on web servers in Australia or
              the United States of America. These terms are governed by the law
              enforced in the State of New South Wales and in the Commonwealth
              of Australia and any dispute about these terms or the contents of
              the Myradio.click site are subject to the exclusive jurisdiction
              of the courts of the State of New South Wales and the courts of
              appeal from them.
            </p>

            <p className="text-lg mb-4">
              7. Any content posted to our API’s is subject to removal if found
              to be offensive. You will be noitified via email prior to any such
              situation.
            </p>
          </div>

          {/* <footer>
          <Bio />
        </footer> */}
        </article>
      </div>
    </Layout>
  )
}

export default TermsAndConditions

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo
    title="Terms and Conditions"
    description="Our Terms and Conditions for our website and available API's"
  />
)
